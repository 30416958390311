import { useReducer, useCallback, useEffect } from "react"
import { View, Dimensions } from "react-native"
import Colors from "../../constants/Colors"
import Config from "../../constants/Config"
import Btn from "../../components/Btn"
import axios from "axios"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false
import Input from "../../components/Input"
import FancySelector from "../../components/FancySelector"
import { useSelector } from "react-redux"
import { ScrollView } from "react-native-gesture-handler"
import Header from "../../components/Header"
import Picker from "../../components/Picker"
import { CommonActions } from "@react-navigation/native"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_STAFFS = "SET_STAFFS"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      if (action.id == "utm_source") {
        return {
          ...state,
          values: {
            ...state.values,
            [action.id]: action.value,
            utm_medium: action.value.includes("ipm") ? ["default"] : "",
          },
        }
      } else {
        return {
          ...state,
          values: {
            ...state.values,
            [action.id]: action.value,
          },
        }
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_STAFFS:
      return {
        ...state,
        values: {
          ...state.values,
          can_be_selected_staffs: action.data,
        },
      }
    default:
      return state
  }
}

const AddLeadScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const capabilities = useSelector((store) => store.user.capabilities)

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      name: "",
      phone: "",
      selected_services: [],
      autoresponse: ["0"],
      region: [],
      utm_source: null,
      utm_medium: null,
      can_be_selected_staffs: [],
      selected_staff: null,
    },
  })

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const add_lead = async () => {
    if (!formState.values.name || !formState.values.phone) {
      alert("وارد کردن نام و شماره الزامیست..")
      return
    }

    if (formState.values.selected_services?.length == 0) {
      alert("حداقل یکی از خدمات را انتخاب کنید")
      return
    }
    if (formState.values.utm_source?.length == 0) {
      alert("منبع لید کجاست؟")
      return
    }
    if (formState.values.region?.length == 0) {
      alert("موقعیت کجاست؟")
      return
    }
    if (
      (formState.values.utm_source[0] == "instagram" ||
        formState.values.utm_source[0] == "google") &&
      formState.values.utm_medium?.length == 0
    ) {
      alert("مدیوم لید کجاست؟")
      return
    }
    const url = Config.aioUrl + "/crm/lead/add"

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("name", formState.values.name)
    formdata.append("phone", formState.values.phone)
    formdata.append("services", formState.values.selected_services.join())
    formdata.append("source", formState.values.utm_source[0])
    formdata.append(
      "medium",
      formState.values.utm_medium ? formState.values.utm_medium[0] : "undefined"
    )
    formdata.append("campaign", "manual")
    formdata.append("autoresponse", parseInt(formState.values.autoresponse[0]))
    formdata.append("region", formState.values.region[0])

    if (
      capabilities.includes("crm_set_new_leads_responsible") &&
      formState.values.selected_staff != null
    )
      formdata.append("staff_id", formState.values.selected_staff.id)

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })

        alert(response.data.msg)

        if (formState.values.region[0] === "in") {
          props.navigation.dispatch(
            CommonActions.reset({
              index: 1,
              routes: [
                {
                  name: "InternationalLeads",
                },
              ],
            })
          )
        } else {
          props.navigation.dispatch(
            CommonActions.reset({
              index: 1,
              routes: [
                {
                  name: "Leads",
                },
              ],
            })
          )
        }
      })
      .catch(function (error) {
        console.log(error)
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const get_staffs = async () => {
    const url = Config.aioUrl + "/staffs/get?department=sales"
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
          return false
        }

        if (response.data.code == 403) {
          alert("شما به این بخش دسترسی ندارید!")
          props.navigation.goBack()
          return false
        }

        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_STAFFS, data: response.data.data })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  useEffect(() => {
    if (capabilities.includes("crm_set_new_leads_responsible")) {
      get_staffs()
    }
  }, [])

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="افزودن لید در گردش" noBack />

      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          flex: 1,
          backgroundColor: "#fff",
          paddingHorizontal: DESKTOP ? "30%" : 0,
          paddingBottom: 30,
        }}
      >
        <View style={{ flex: 1 }}>
          <Input label="نام" id="name" onChange={inputChangeHandler} textAlign="center" />
          <Input label="شماره" id="phone" onChange={inputChangeHandler} textAlign="center" />
          <FancySelector
            column={3}
            id="utm_source"
            title="منبع مشتری کجاست؟"
            options={[
              {
                item: "تماس بازاریابی",
                id: "coldcalling",
                icon: "call-outline",
              },
              {
                item: "اس ام اس",
                id: "sms",
                icon: "chatbubble-ellipses-outline",
              },
              {
                item: "تلگرام",
                id: "telegram",
                icon: "ios-paper-plane-sharp",
              },
              {
                item: "اینستاگرام",
                id: "instagram",
                icon: "md-logo-instagram",
              },
              { item: "گوگل", id: "google", icon: "md-logo-google" },
              { item: "معرفی", id: "wom", icon: "md-heart-half-sharp" },
              {
                item: "لندینگ سایت",
                id: "landing",
                icon: "ios-desktop-outline",
              },
              {
                item: "بازاریابی حضوری",
                id: "ipm",
                icon: "people-sharp",
              },
            ]}
            onChange={inputChangeHandler}
            single
          />
          {(formState.values.utm_source == "instagram" ||
            formState.values.utm_source == "google") && (
            <FancySelector
              column={2}
              id="utm_medium"
              title="مدیوم مشتری کجاست؟"
              options={
                formState.values.utm_source == "instagram"
                  ? [
                      { item: "دایرکت", id: "dm" },
                      // { item: "تبلیغ", id: "paid-ad" },
                      { item: "تماس", id: "call" },
                    ]
                  : [
                      { item: "سئو", id: "seo" },
                      { item: "گوگل ادز", id: "cpc" },
                    ]
              }
              onChange={inputChangeHandler}
              single
            />
          )}

          <FancySelector
            column={2}
            id="selected_services"
            title="کدام خدمت مد نظر مشتری است؟"
            options={[
              { item: "لوگو", id: "logo", icon: "bulb-outline" },
              { item: "سایت", id: "site", icon: "globe-outline" },
              {
                item: "لوگومون",
                id: "logomoon",
                icon: "checkmark-circle-outline",
              },
              {
                item: "اپلیکیشن",
                id: "app",
                icon: "md-phone-portrait-outline",
              },

              { item: "ثبت مالکیت برند", id: "brand-registration", icon: "" },
              { item: "ایده پردازی اسم برند", id: "brand-naming", icon: "" },
              { item: "ثبت شرکت", id: "company-registration", icon: "" },
              { item: "کارت بازرگانی", id: "trade-license", icon: "" },

              { item: "بسته بندی", id: "package-design", icon: "" },
              { item: "ست اداری", id: "office-set", icon: "" },

              {
                item: "موارد دیگر",
                id: "other",
                icon: "checkmark-circle-outline",
                column: 2,
              },
            ]}
            onChange={inputChangeHandler}
          />

          <FancySelector
            column={2}
            id="autoresponse"
            title="آیا تعرفه در واتسپ ارسال شود؟"
            options={[
              { item: "بله", id: "1", icon: "thumbs-up-outline" },
              { item: "خیر", id: "0", icon: "thumbs-down-outline" },
            ]}
            onChange={inputChangeHandler}
            single
          />

          <FancySelector
            column={
              capabilities.includes("role_foreign_jurisdiction") &&
              capabilities.includes("role_domestic_jurisdiction")
                ? 2
                : 1
            }
            id="region"
            title="نوع موقعیت لید؟"
            options={[
              capabilities.includes("role_foreign_jurisdiction")
                ? {
                    item: "بین‌الملل",
                    id: "in",
                    icon: "earth-sharp",
                  }
                : false,
              capabilities.includes("role_domestic_jurisdiction")
                ? {
                    item: "داخلی",
                    id: "ir",
                    icon: "flag",
                  }
                : false,
            ].filter((item) => item)}
            onChange={inputChangeHandler}
            single
          />

          {capabilities.includes("crm_set_new_leads_responsible") && (
            <View>
              <Picker
                id="selected_staff"
                onChange={inputChangeHandler}
                label="پرسنل (اختیاری)"
                options={formState.values.can_be_selected_staffs}
              />
            </View>
          )}

          <Btn
            title={formState.values.autoresponse != 0 ? "ثبت لید و ارسال پیام اتوماتیک" : "ثبت لید"}
            onPress={() => {
              !formState.values.loading ? add_lead() : null
            }}
            wrapperStyle={{ marginTop: 20 }}
            loading={formState.values.loading}
          />
        </View>
      </ScrollView>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default AddLeadScreen
